
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0px;
  padding: 0px;
  text-decoration: none;
  outline: none;
  border: 0px;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0.5px;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}

html {
  scroll-behavior: smooth;
}
.bg-primary{
  background-color: #28a745;
}
.bg-secondary{
  background-color: #7cc143;
}

/* == SCROLL BAR == */
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: #050718;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #28a745;
}

/* == || SCROLL BAR == */
/* == GENERAL == */
.container {
  width: 100%;
  max-width: 1400px !important;
  margin: auto;
  padding: 0px 15px;
}

/* == || GENERAL == */

/* == Header == */
header {
  height: fit-content;
  width: 100%;
}

.header-container {
  padding: 10px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}


.header-contacts {
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-contacts .contact {
  padding: 10px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 20px;
}

.header-contacts .contact .pad {
  padding: 0px 5px;
}
.header-contacts hr{
  height: 20px;
  width: 0px;
  background-color: #1c1b1f;
}

.header-contacts .contact p:nth-child(1) {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  color: #7CC143;
}

.header-contacts .contact p:nth-child(2) {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  color: #7CC143;
}

nav {
  width: 100%;
  border-top: 1px solid rgba(28, 27, 31, 0.2);
}

.nav-container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav-container .logo {
  display: none;
}

.links {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.links a {
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  padding: 10px 10px;
  border-bottom: 4px solid #ffffff00;
}

.links .active-link {
  color: #7CC143;
  border-bottom: 4px solid #ffffff;
}

.links a:hover {
  color: #7CC143;
  border-bottom: 4px solid #7CC143;
}
.dropdown {
  position: absolute;
  width: fit-content;
  height: fit-content;
  top: 240px;
  /* K.A.I.C.T. - Light Blue */
  background: #eff1fb;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 16px;
  gap: 15px;
  position: absolute;
  left: 55%;
  animation-name: nav-dropdwon;
  animation-duration: 200ms;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}
.dropdown-link {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #1c1b1f;
}
.dropdown-link:hover {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #7CC143;
}

.nav-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #25518c;
}

.nav-logo img {
  margin: 0px 10px 0px;
}

.menu-bar {
  padding: 5px;
}

.menu-btn {
  font-size: 28px;
  padding: 10px 20px;
}

.hero-space-top {
  padding-top: 4rem;
}

/* == || Header == */
.triangle {
  width: 0px;
  height: 0px;
  border-left: 40px solid transparent;
  border-right: 40px solid transparent;
  border-bottom: 40px solid #28a745;

}
.triangle-active {
  width: 0px;
  height: 0px;
  border-left: 40px solid transparent;
  border-right: 40px solid transparent;
  border-bottom: 40px solid #7cc143;

}
.about-section h3{
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 150%;
  color: #28a745;
}
.about-section p{
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  /* font-size: 24px; */
  line-height: 150%;
  color: #050718;
}
.recent-projects h3{
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 150%;
  color: #ffffff;
}
.recent-projects span{
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 300;
  font-size: 18px;
  line-height: 28px;
  color: #ffffff;
}
.recent-projects p{
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  /* font-size: 24px; */
  line-height: 150%;
  color: #ffffff;
  text-align: justify;
}
.shop-categories h2{
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-size: 36px;
  line-height: 58px;
  color: #ffffff;
}
.shop-categories h3{
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-size: 28px;
  line-height: 58px;
  color: #7CC143;
}


/* == SERVICES == */
.shared-hero h1{
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 58px;
  color: #ffffff;
}
.botton-content h3{
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 58px;
  color: #050718;
}
.botton-content button{
border: 3px solid #28a745;
padding: 10px 20px;
color: #28a745;
font-size: 24px;
}
.botton-content .services button{
  border: 3px solid #28a745;
padding: 10px 20px;
background-color: #28a745 ;
color: #ffffff;
font-size: 20px;
font-weight: 300;
margin-top: 20px;
}
/* == Footer == */
footer {
  height: fit-content;
  padding: 80px 0px 40px;
  background: #606060;
}
.botton-content p{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  color: #050718;

}

.footer-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}

.col {
  width: 100%;
  margin: 10px;
}

.col.one {
  max-width: 600px;
  min-width: 280px;
}

.col.one h4 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 36px;
  color: #FCFCFC;
  margin: 10px 0px;
}

.col.one p {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #FCFCFC;
  margin: 10px 0px 13px;
}

.col.two,
.col.three {
  max-width: 300px;
  min-width: 280px;
  margin: 10px;
}

.col h4 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #FCFCFC;
  margin: 10px 0px;
}

.col ul {
  margin: 30px 0px 0px;
}

.col ul li {
  padding: 5px 0px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.col ul li a {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #FCFCFC;
}

.bottom-footer p {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #FCFCFC;
  margin: 10px;
}

.red-border {
  border: 1px dotted red;
}

/* projects cards */
.project-card {
  position: relative;
}

.project-info {
  color: #fff;
  background-color: #7cc143;
  opacity: 0.9;
  width: 100%;
  position: absolute;
  bottom: 0;
  padding: .5rem;
}


/* contact form */
.contact-form-wrapper {
	margin-bottom: 1rem;
	margin-top: 1rem;
}

/* style input elements and text area of the form with id contact-us */
form#contact-us {
  color: #fff;
}

form#contact-us input[type="text"]  {
  background-color: #a6a6a6;
  height: 2.5rem;
}

form#contact-us textarea  {
  background-color: #a6a6a6;
}

form#contact-us #contact-us ::placeholder {
  color: white !important;
}
/* == || Footer == */

ul.socials-icons li a img:hover {
  filter: invert(100%);
}

/* media queries */

/* CSS rules for mobile screens */
@media only screen and (max-width: 767px) {
  .nav-container {
    display: none;
  }


  .about-section {
    margin-top: 20px;
    margin-bottom: 20px;
  }

}


@media only screen and (min-width: 767px) {
  .hero-space-top {
    padding-top: 0;
  }

}
